<template>
    <section class="hero">
        <div class="rotating-text">
            <div class="rotating-text__window">
                <template v-for="(item, index) in rotator" :key="item">
                    <p v-if=" index < (rotator.length - 1) "

                    >
                        {{ item }}
                    </p>
                    <h1 v-else>
                        {{ item }}
                    </h1>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
    import { onMounted } from 'vue';

    export default {
        setup() {
            const rotator = [
                'HTML5',
                'CSS3',
                'ES2017',
                'SCSS',
                'Pug',
                'Gulp',
                'Webpack',
                'Git',
                'Vue 2 & 3',
                'Email',
                'Banners',
                'Front End',
                'Dan Kiser'
            ];

            onMounted( () => {
                function rotateText() {
                    const rotators = document.querySelectorAll( '.rotating-text__window > *' );
                    const duration = 750;

                    rotators.forEach( (el, index) => {
                        const thisDelay = (index + 1) * duration;
                        const options = {
                            fill: 'forwards',
                            duration: duration,
                            delay: thisDelay
                        };
                        const frames =
                            index === 0
                            ? [{ transform: 'translateY(-100%)'}]
                            : index < rotators.length - 1
                                ? [
                                    { transform: 'translateY(100%)' },
                                    { transform: 'translateY(0)' },
                                    { transform: 'translateY(-100%)' }
                                ]
                                : [
                                    { transform: 'translateY(100%)' },
                                    { transform: 'translateY(0)' }
                                ];

                        el.animate( frames, options );
                    } );
                }

                rotateText();
            } );

            return { rotator };
        }
    };
</script>

<style lang="scss" scoped>
    //@import for special fonts on rotator
</style>
